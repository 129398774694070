import * as React from "react"
import {graphql} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';

import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';
import styled from "styled-components";

const StatueImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StatueImage = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  margin-top: 24px;
`

export const getMonument = graphql`
query getMonument {
    datoCmsMemorial{
      title,
      body {
        value
      },
      statueImg {
        url
        alt
      }
    }
  }
`
  

const MonumentPage = ({data}) => {
  return (
    <StaticPageTemplate title={data.datoCmsMemorial.title}>
      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - o pomniku" link="https://debica-dzieciutracone.pl/o-pomniku" ogTitle="O pomniku - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="O pomniku, który stanowi miejsce pamięci i modlitwy dla rodziców, którym nie było dane pożegnać i pochować swoich dzieci."/>

       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(data.datoCmsMemorial.body.value)}}></div>
      {data.datoCmsMemorial.statueImg
        ? <StatueImageContainer><StatueImage src={data.datoCmsMemorial.statueImg.url} alt={data.datoCmsMemorial.statueImg.alt}/></StatueImageContainer>
        : null
      }
       
    </StaticPageTemplate>
  )
}

export default MonumentPage